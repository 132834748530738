import React from "react";
import { IconProps, defaultIconColor } from ".";

export default function LogoutIcon({
  color = defaultIconColor,
  size = 18,
}: IconProps) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_9304_2293)">
        <path
          d="M3.6875 16.4375H10.0625C10.3442 16.4372 10.6143 16.3251 10.8134 16.1259C11.0126 15.9268 11.1247 15.6567 11.125 15.375V13.7812H10.0625V15.375H3.6875V2.625H10.0625V4.21875H11.125V2.625C11.1247 2.34331 11.0126 2.07324 10.8134 1.87406C10.6143 1.67487 10.3442 1.56282 10.0625 1.5625H3.6875C3.40581 1.56282 3.13574 1.67487 2.93656 1.87406C2.73737 2.07324 2.62532 2.34331 2.625 2.625V15.375C2.62532 15.6567 2.73737 15.9268 2.93656 16.1259C3.13574 16.3251 3.40581 16.4372 3.6875 16.4375Z"
          fill={color}
        />
        <path
          d="M11.4363 11.4363L13.3414 9.53125H5.8125V8.46875H13.3414L11.4363 6.56369L12.1875 5.8125L15.375 9L12.1875 12.1875L11.4363 11.4363Z"
          fill={color}
        />
      </g>
      <defs>
        <clipPath id="clip0_9304_2293">
          <rect
            width="17"
            height="17"
            fill="white"
            transform="translate(0.5 0.5)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}

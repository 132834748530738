import React from "react";
import { IconProps, defaultIconColor } from ".";

export default function HomeIcon({
  color = defaultIconColor,
  size = 18,
}: IconProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 21 20"
      fill="none"
    >
      <path
        d="M8.01699 2.36908L3.52533 5.86908C2.77533 6.45241 2.16699 7.69408 2.16699 8.63575V14.8107C2.16699 16.7441 3.74199 18.3274 5.67533 18.3274H15.3253C17.2587 18.3274 18.8337 16.7441 18.8337 14.8191V8.75241C18.8337 7.74408 18.1587 6.45241 17.3337 5.87741L12.1837 2.26908C11.017 1.45241 9.14199 1.49408 8.01699 2.36908Z"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.5 12.252C7.5 11.1474 8.39543 10.252 9.5 10.252H11.5C12.6046 10.252 13.5 11.1474 13.5 12.252V18.252H7.5V12.252Z"
        stroke={color}
        strokeWidth="0.75"
      />
    </svg>
  );
}

import React from "react";
import { IconProps, defaultIconColor } from ".";

export default function CartIcon({
  color = defaultIconColor,
  size = 18,
}: IconProps) {
  return (
    <svg
      width={size}
      height={size}
      viewBox='0 0 18 20'
      fill={color}
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M15.7334 19.5H2.56309C2.07166 19.5 1.65395 19.325 1.30995 18.975C0.965945 18.625 0.793945 18.2 0.793945 17.7V6.3C0.793945 5.8 0.965945 5.375 1.30995 5.025C1.65395 4.675 2.07166 4.5 2.56309 4.5H4.72537C4.72537 3.25 5.15521 2.18733 6.01488 1.312C6.87521 0.437333 7.91966 0 9.14823 0C10.3768 0 11.4212 0.437333 12.2816 1.312C13.1412 2.18733 13.5711 3.25 13.5711 4.5H15.7334C16.2248 4.5 16.6425 4.675 16.9865 5.025C17.3305 5.375 17.5025 5.8 17.5025 6.3V17.7C17.5025 18.2 17.3305 18.625 16.9865 18.975C16.6425 19.325 16.2248 19.5 15.7334 19.5ZM6.19966 4.5H12.0968C12.0968 3.66667 11.8101 2.95833 11.2368 2.375C10.6635 1.79167 9.96727 1.5 9.14823 1.5C8.32918 1.5 7.63299 1.79167 7.05966 2.375C6.48632 2.95833 6.19966 3.66667 6.19966 4.5ZM15.7334 18C15.7989 18 15.8644 17.9667 15.9299 17.9C15.9955 17.8333 16.0282 17.7667 16.0282 17.7V6.3C16.0282 6.23333 15.9955 6.16667 15.9299 6.1C15.8644 6.03333 15.7989 6 15.7334 6H2.56309C2.49756 6 2.43204 6.03333 2.36652 6.1C2.30099 6.16667 2.26823 6.23333 2.26823 6.3V17.7C2.26823 17.7667 2.30099 17.8333 2.36652 17.9C2.43204 17.9667 2.49756 18 2.56309 18H15.7334ZM9.14823 11.5C10.3768 11.5 11.4212 11.0627 12.2816 10.188C13.1412 9.31267 13.5711 8.25 13.5711 7H12.0968C12.0968 7.83333 11.8101 8.54167 11.2368 9.125C10.6635 9.70833 9.96727 10 9.14823 10C8.32918 10 7.63299 9.70833 7.05966 9.125C6.48632 8.54167 6.19966 7.83333 6.19966 7H4.72537C4.72537 8.25 5.15521 9.31267 6.01488 10.188C6.87521 11.0627 7.91966 11.5 9.14823 11.5Z'
        fillOpacity='1'
      />
    </svg>
  );
}

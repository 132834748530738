import React from "react"
import { IconProps, defaultIconColor } from "."

export default function LensList({
  color = defaultIconColor,
  size = 36,
}: IconProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 36 36"
      fill={color}
    >
      <mask
        id="mask0_3564_46694"
        style={{ maskType: "alpha" }}
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="36"
        height="36"
      >
        <rect
          width="36"
          height="36"
          transform="matrix(-1 0 0 1 36 0)"
          fill="#D9D9D9"
        />
      </mask>
      <g mask="url(#mask0_3564_46694)">
        <path
          d="M23.8127 24.825C23.4877 24.825 23.2187 24.7125 23.0057 24.4875C22.7937 24.2625 22.6877 23.9875 22.6877 23.6625C22.6877 23.3375 22.7937 23.0625 23.0057 22.8375C23.2187 22.6125 23.4877 22.5 23.8127 22.5C24.1627 22.5 24.4442 22.6125 24.6572 22.8375C24.8692 23.0625 24.9752 23.3375 24.9752 23.6625C24.9752 23.9875 24.8627 24.2625 24.6377 24.4875C24.4127 24.7125 24.1377 24.825 23.8127 24.825ZM23.8127 19.1625C23.4877 19.1625 23.2187 19.05 23.0057 18.825C22.7937 18.6 22.6877 18.325 22.6877 18C22.6877 17.675 22.7937 17.4 23.0057 17.175C23.2187 16.95 23.4877 16.8375 23.8127 16.8375C24.1627 16.8375 24.4442 16.95 24.6572 17.175C24.8692 17.4 24.9752 17.675 24.9752 18C24.9752 18.325 24.8627 18.6 24.6377 18.825C24.4127 19.05 24.1377 19.1625 23.8127 19.1625ZM23.8127 13.5C23.4877 13.5 23.2187 13.3875 23.0057 13.1625C22.7937 12.9375 22.6877 12.6625 22.6877 12.3375C22.6877 12.0125 22.7937 11.7375 23.0057 11.5125C23.2187 11.2875 23.4877 11.175 23.8127 11.175C24.1627 11.175 24.4442 11.2875 24.6572 11.5125C24.8692 11.7375 24.9752 12.0125 24.9752 12.3375C24.9752 12.6625 24.8627 12.9375 24.6377 13.1625C24.4127 13.3875 24.1377 13.5 23.8127 13.5ZM19.2002 24.4125H11.1377V22.9125H19.2002V24.4125ZM19.2002 18.75H11.1377V17.25H19.2002V18.75ZM19.2002 13.0875H11.1377V11.5875H19.2002V13.0875ZM27.5627 30C28.2627 30 28.8437 29.769 29.3057 29.307C29.7687 28.844 30.0002 28.2625 30.0002 27.5625V8.4375C30.0002 7.7375 29.7687 7.1565 29.3057 6.6945C28.8437 6.2315 28.2627 6 27.5627 6H8.43774C7.73774 6 7.15624 6.2315 6.69324 6.6945C6.23124 7.1565 6.00024 7.7375 6.00024 8.4375V27.5625C6.00024 28.2625 6.23124 28.844 6.69324 29.307C7.15624 29.769 7.73774 30 8.43774 30H27.5627Z"
          fill="#BB202E"
        />
      </g>
    </svg>
  )
}

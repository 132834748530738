import React from "react";
import { IconProps, defaultIconColor } from ".";

export default function WhatsAppIcon({
  color = defaultIconColor,
  size = 18,
}: IconProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        d="M6.9 20.602C8.4 21.502 10.2 22.002 12 22.002C17.5 22.002 22 17.502 22 12.002C22 6.50195 17.5 2.00195 12 2.00195C6.5 2.00195 2 6.50195 2 12.002C2 13.802 2.5 15.502 3.3 17.002L2.44044 20.3079C2.24572 21.0569 2.93892 21.7337 3.68299 21.5211L6.9 20.602Z"
        stroke={color}
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16.5 14.8505C16.5 15.0125 16.4639 15.179 16.3873 15.341C16.3107 15.503 16.2116 15.656 16.0809 15.8C15.86 16.043 15.6167 16.2185 15.3418 16.331C15.0714 16.4435 14.7784 16.502 14.4629 16.502C14.0033 16.502 13.512 16.394 12.9937 16.1735C12.4755 15.953 11.9572 15.656 11.4434 15.2825C10.9251 14.9045 10.4339 14.486 9.9652 14.0225C9.501 13.5545 9.08187 13.064 8.70781 12.551C8.33826 12.038 8.04081 11.525 7.82449 11.0165C7.60816 10.5035 7.5 10.013 7.5 9.54495C7.5 9.23895 7.55408 8.94645 7.66224 8.67645C7.77041 8.40195 7.94166 8.14995 8.18052 7.92495C8.46895 7.64145 8.78443 7.50195 9.11793 7.50195C9.24412 7.50195 9.37031 7.52895 9.48297 7.58295C9.60015 7.63695 9.70381 7.71795 9.78493 7.83495L10.8305 9.30645C10.9116 9.41895 10.9702 9.52245 11.0108 9.62145C11.0513 9.71595 11.0739 9.81045 11.0739 9.89595C11.0739 10.004 11.0423 10.112 10.9792 10.2155C10.9206 10.319 10.835 10.427 10.7268 10.535L10.3843 10.8905C10.3348 10.94 10.3122 10.9985 10.3122 11.0705C10.3122 11.1065 10.3167 11.138 10.3257 11.174C10.3393 11.21 10.3528 11.237 10.3618 11.264C10.4429 11.4125 10.5826 11.606 10.7809 11.84C10.9837 12.074 11.2 12.3125 11.4344 12.551C11.6778 12.7895 11.9121 13.01 12.151 13.2125C12.3853 13.4105 12.5791 13.5455 12.7323 13.6265C12.7549 13.6355 12.7819 13.649 12.8135 13.6625C12.8495 13.676 12.8856 13.6805 12.9261 13.6805C13.0028 13.6805 13.0613 13.6535 13.1109 13.604L13.4534 13.2665C13.5661 13.154 13.6743 13.0685 13.7779 13.0145C13.8816 12.9515 13.9852 12.92 14.0979 12.92C14.1835 12.92 14.2737 12.938 14.3728 12.9785C14.472 13.019 14.5756 13.0775 14.6883 13.154L16.18 14.2115C16.2972 14.2925 16.3783 14.387 16.4279 14.4995C16.473 14.612 16.5 14.7245 16.5 14.8505Z"
        stroke={color}
        strokeMiterlimit="10"
      />
    </svg>
  );
}

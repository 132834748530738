import React from "react";
import { IconProps, defaultIconColor } from ".";

export default function MinusIcon({
  color = defaultIconColor,
  size = 24,
}: IconProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height={size}
      viewBox="0 -960 960 960"
      width={size}
      fill={color}
    >
      <path d="M200-440v-80h560v80H200Z" />
    </svg>
  );
}

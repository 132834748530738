import React from "react";
import { IconProps, defaultIconColor } from ".";

export default function EmailIcon({
  color = defaultIconColor,
  size = 18,
}: IconProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 25 24"
      fill="none"
    >
      <path
        d="M7.99805 9.75098L10.9247 11.5554C11.8905 12.1508 13.1094 12.1508 14.0751 11.5554L17.0018 9.75098"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <rect
        x="3.49609"
        y="4.99902"
        width="18.0075"
        height="14.0058"
        rx="3"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

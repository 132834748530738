import React from "react";
import { IconProps, defaultIconColor } from ".";

export default function PrinterIcon({
  color = defaultIconColor,
  size = 18,
}: IconProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 21 20"
      fill="none"
    >
      <path
        d="M6.54199 5.83561H14.4587V4.16895C14.4587 2.50228 13.8337 1.66895 11.9587 1.66895H9.04199C7.16699 1.66895 6.54199 2.50228 6.54199 4.16895V5.83561Z"
        fill={color}
        stroke={color}
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.8337 12.502V15.8353C13.8337 17.502 13.0003 18.3353 11.3337 18.3353H9.66699C8.00033 18.3353 7.16699 17.502 7.16699 15.8353V12.502H13.8337Z"
        stroke={color}
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M18 8.33496V12.5016C18 14.1683 17.1667 15.0016 15.5 15.0016H13.8333V12.5016H7.16667V15.0016H5.5C3.83333 15.0016 3 14.1683 3 12.5016V8.33496C3 6.66829 3.83333 5.83496 5.5 5.83496H15.5C17.1667 5.83496 18 6.66829 18 8.33496Z"
        stroke={color}
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.6663 12.502H13.658H6.33301"
        stroke={color}
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.33301 9.16895H8.83301"
        stroke={color}
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

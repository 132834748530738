import React from "react";
import { IconProps, defaultIconColor } from ".";

export default function PhoneIcon({
  color = defaultIconColor,
  size = 18,
}: IconProps) {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={size}
      height={size}
      viewBox='0 0 21 20'
      fill='none'
    >
      <path
        d='M18.8087 15.2773C18.8087 15.5773 18.742 15.8856 18.6003 16.1856C18.4587 16.4856 18.2753 16.7689 18.0337 17.0356C17.6253 17.4856 17.1753 17.8106 16.667 18.0189C16.167 18.2273 15.6253 18.3356 15.042 18.3356C14.192 18.3356 13.2837 18.1356 12.3253 17.7273C11.367 17.3189 10.4087 16.7689 9.45866 16.0773C8.50033 15.3773 7.59199 14.6023 6.72533 13.7439C5.86699 12.8773 5.09199 11.9689 4.40033 11.0189C3.71699 10.0689 3.16699 9.11895 2.76699 8.17728C2.36699 7.22728 2.16699 6.31895 2.16699 5.45228C2.16699 4.88561 2.26699 4.34395 2.46699 3.84395C2.66699 3.33561 2.98366 2.86895 3.42533 2.45228C3.95866 1.92728 4.54199 1.66895 5.15866 1.66895C5.39199 1.66895 5.62533 1.71895 5.83366 1.81895C6.05033 1.91895 6.24199 2.06895 6.39199 2.28561L8.32533 5.01061C8.47533 5.21895 8.58366 5.41061 8.65866 5.59395C8.73366 5.76895 8.77533 5.94395 8.77533 6.10228C8.77533 6.30228 8.71699 6.50228 8.60033 6.69395C8.49199 6.88561 8.33366 7.08561 8.13366 7.28561L7.50033 7.94395C7.40866 8.03561 7.36699 8.14395 7.36699 8.27728C7.36699 8.34395 7.37533 8.40228 7.39199 8.46895C7.41699 8.53561 7.44199 8.58561 7.45866 8.63561C7.60866 8.91061 7.86699 9.26895 8.23366 9.70228C8.60866 10.1356 9.00866 10.5773 9.44199 11.0189C9.89199 11.4606 10.3253 11.8689 10.767 12.2439C11.2003 12.6106 11.5587 12.8606 11.842 13.0106C11.8837 13.0273 11.9337 13.0523 11.992 13.0773C12.0587 13.1023 12.1253 13.1106 12.2003 13.1106C12.342 13.1106 12.4503 13.0606 12.542 12.9689L13.1753 12.3439C13.3837 12.1356 13.5837 11.9773 13.7753 11.8773C13.967 11.7606 14.1587 11.7023 14.367 11.7023C14.5253 11.7023 14.692 11.7356 14.8753 11.8106C15.0587 11.8856 15.2503 11.9939 15.4587 12.1356L18.217 14.0939C18.4337 14.2439 18.5837 14.4189 18.6753 14.6273C18.7587 14.8356 18.8087 15.0439 18.8087 15.2773Z'
        stroke={color}
        strokeMiterlimit='10'
      />
    </svg>
  );
}

import { IconProps, defaultIconColor } from ".";

export default function ArrowUp({
  color = defaultIconColor,
  size = 24,
}: IconProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height={size}
      viewBox="0 -960 960 960"
      width={size}
      fill={color}
    >
      <path d="m296-345-56-56 240-240 240 240-56 56-184-184-184 184Z" />
    </svg>
  );
}

import { IconProps, defaultIconColor } from ".";

export default function Add({
  color = defaultIconColor,
  size = 14,
}: IconProps) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 14 14"
      fill={color}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M14 8H8V14H6V8H0V6H6V0H8V6H14V8Z" fill="white" />
    </svg>
  );
}

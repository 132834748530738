import React from "react";
import { IconProps, defaultIconColor } from ".";

export default function GuarrantyIcon({
  color = defaultIconColor,
  size = 18,
}: IconProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 20 20"
      fill="none"
    >
      <path
        d="M9.78464 14.7915H14.718C14.643 14.8581 14.568 14.9165 14.493 14.9831L10.9346 17.6498C9.75963 18.5248 7.84297 18.5248 6.65964 17.6498L3.09297 14.9831C2.30964 14.3998 1.66797 13.1081 1.66797 12.1331L1.66797 5.95813C1.66797 4.94147 2.44297 3.81647 3.39297 3.45813L7.54297 1.8998C8.2263 1.64147 9.35964 1.64147 10.043 1.8998L14.1846 3.45813C14.9763 3.75813 15.6513 4.59147 15.8596 5.44147L9.7763 5.44147C9.59297 5.44147 9.4263 5.44981 9.26796 5.44981C7.7263 5.54148 7.3263 6.0998 7.3263 7.85813V12.3831C7.33463 14.2998 7.82631 14.7915 9.78464 14.7915Z"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.33594 9.34961L18.3359 9.34961"
        stroke={color}
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M18.3359 7.84981V12.4748C18.3193 14.3248 17.8109 14.7831 15.8859 14.7831L9.78594 14.7831C7.82761 14.7831 7.33594 14.2915 7.33594 12.3665L7.33594 7.84147C7.33594 6.09147 7.73594 5.53312 9.27761 5.43312C9.43594 5.43312 9.60261 5.4248 9.78594 5.4248L15.8859 5.4248C17.8443 5.43314 18.3359 5.91647 18.3359 7.84981Z"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.43359 12.7168H10.5419"
        stroke={color}
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.293 12.7168H15.018"
        stroke={color}
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

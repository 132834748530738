import { IconProps, defaultIconColor } from "."

export default function UploadIcon({
  color = defaultIconColor,
  size = 18,
}: IconProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="67"
      height="48"
      viewBox="0 0 67 48"
      fill="none"
    >
      <path
        d="M54.5 21C54.5 9.39 45.11 0 33.5 0C23.84 0 15.71 6.54 13.28 15.45C5.96 17.13 0.5 23.67 0.5 31.5C0.5 40.62 7.88 48 17 48C22.28 48 47.75 48 53 48C60.47 47.97 66.5 41.91 66.5 34.44C66.5 27.45 61.25 21.78 54.5 21ZM36.5 27V33C36.5 34.65 35.15 36 33.5 36C31.85 36 30.5 34.65 30.5 33V27H25.13C23.78 27 23.12 25.38 24.08 24.45L32.45 16.08C33.05 15.48 33.98 15.48 34.58 16.08L42.95 24.45C43.88 25.38 43.22 27 41.9 27H36.5Z"
        fill="#8E8E8E"
      />
    </svg>
  )
}

import React from "react";
import { IconProps, defaultIconColor } from ".";

export default function SearchIcon({
  color = defaultIconColor,
  size = 18,
}: IconProps) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 17 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15.6495 17.575L9.45751 11.3C8.96608 11.7167 8.40093 12.0417 7.76208 12.275C7.12322 12.5083 6.46798 12.625 5.79636 12.625C4.10913 12.625 2.68398 12.0333 1.52094 10.85C0.357891 9.66667 -0.223633 8.21667 -0.223633 6.5C-0.223633 4.8 0.357891 3.354 1.52094 2.162C2.68398 0.970667 4.10913 0.375 5.79636 0.375C7.46722 0.375 8.88417 0.966667 10.0472 2.15C11.2103 3.33333 11.7918 4.78333 11.7918 6.5C11.7918 7.21667 11.6771 7.9 11.4478 8.55C11.2185 9.2 10.9072 9.76667 10.5141 10.25L16.6815 16.525L15.6495 17.575ZM5.79636 11.125C7.0577 11.125 8.12672 10.675 9.00343 9.775C9.87948 8.875 10.3175 7.78333 10.3175 6.5C10.3175 5.21667 9.87948 4.125 9.00343 3.225C8.12672 2.325 7.0577 1.875 5.79636 1.875C4.51865 1.875 3.44177 2.325 2.56571 3.225C1.68901 4.125 1.25065 5.21667 1.25065 6.5C1.25065 7.78333 1.68901 8.875 2.56571 9.775C3.44177 10.675 4.51865 11.125 5.79636 11.125Z"
        fill={color}
        fillOpacity="1"
      />
    </svg>
  );
}

import React from "react";
import { IconProps, defaultIconColor } from ".";

export default function LocationIcon({
  color = defaultIconColor,
  size,
}: IconProps) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 16 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.06708 11.1341C7.36158 11.1341 6.67192 10.9249 6.08532 10.533C5.49871 10.141 5.04151 9.58393 4.77153 8.93214C4.50155 8.28034 4.43091 7.56312 4.56854 6.87117C4.70618 6.17923 5.04591 5.54364 5.54477 5.04477C6.04364 4.54591 6.67923 4.20618 7.37117 4.06854C8.06312 3.93091 8.78034 4.00155 9.43214 4.27153C10.0839 4.54151 10.641 4.99871 11.033 5.58532C11.4249 6.17192 11.6341 6.86157 11.6341 7.56708C11.6331 8.51279 11.2569 9.41947 10.5882 10.0882C9.91947 10.7569 9.01279 11.1331 8.06708 11.1341ZM8.06708 5.42683C7.64378 5.42683 7.22998 5.55235 6.87802 5.78753C6.52606 6.0227 6.25174 6.35696 6.08975 6.74804C5.92776 7.13912 5.88537 7.56945 5.96796 7.98462C6.05054 8.39978 6.25438 8.78114 6.55369 9.08046C6.85301 9.37978 7.23437 9.58361 7.64953 9.6662C8.0647 9.74878 8.49503 9.70639 8.88611 9.5444C9.27719 9.38241 9.61145 9.10809 9.84662 8.75613C10.0818 8.40417 10.2073 7.99038 10.2073 7.56708C10.2067 6.99964 9.98101 6.45562 9.57977 6.05438C9.17853 5.65314 8.63451 5.42745 8.06708 5.42683Z"
        fill={color}
      />
      <path
        d="M8.00038 19.5L2.16474 12.5714C2.1316 12.5317 1.92386 12.257 1.92386 12.257C0.926913 10.9351 0.388296 9.32044 0.390633 7.66071C0.390633 5.62897 1.19237 3.68043 2.61948 2.24377C4.04658 0.807109 5.98215 0 8.00038 0C10.0186 0 11.9542 0.807109 13.3813 2.24377C14.8084 3.68043 15.6101 5.62897 15.6101 7.66071C15.6126 9.31976 15.0744 10.9338 14.0779 12.2553L14.0769 12.257C14.0769 12.257 13.8694 12.5317 13.8384 12.5686L8.00038 19.5ZM3.0281 11.4179C3.02879 11.4184 3.18957 11.6326 3.22637 11.6787L8.00038 17.3466L12.7807 11.6707C12.8111 11.6322 12.9732 11.4166 12.9736 11.416C13.788 10.3359 14.2281 9.01675 14.2265 7.66071C14.2265 5.99838 13.5706 4.40412 12.4029 3.22867C11.2353 2.05322 9.65166 1.39286 8.00038 1.39286C6.3491 1.39286 4.76545 2.05322 3.59782 3.22867C2.43019 4.40412 1.77422 5.99838 1.77422 7.66071C1.77269 9.01748 2.21316 10.3373 3.0281 11.4179Z"
        fill={color}
      />
    </svg>
  );
}

import React from "react";
import { IconProps, defaultIconColor } from ".";

export default function AparatIcon({
  color = defaultIconColor,
  size = 18,
}: IconProps) {
  return (
    <svg
      width={size}
      height={size}
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M12 19.752C16.2802 19.752 19.75 16.2822 19.75 12.002C19.75 7.72175 16.2802 4.25195 12 4.25195C7.71979 4.25195 4.25 7.72175 4.25 12.002C4.25 16.2822 7.71979 19.752 12 19.752Z'
        stroke={color}
        strokeMiterlimit='10'
      />
      <path
        d='M12.499 12.0024C12.499 12.2783 12.2754 12.5019 11.9995 12.5019C11.7236 12.5019 11.5 12.2783 11.5 12.0024C11.5 11.7266 11.7236 11.5029 11.9995 11.5029C12.2754 11.5029 12.499 11.7266 12.499 12.0024Z'
        stroke={color}
      />
      <path
        d='M16.6514 10.1162C16.6514 10.8066 16.0917 11.3662 15.4014 11.3662C14.711 11.3662 14.1514 10.8066 14.1514 10.1162C14.1514 9.42586 14.711 8.86621 15.4014 8.86621C16.0917 8.86621 16.6514 9.42586 16.6514 10.1162Z'
        stroke={color}
      />
      <path
        d='M15.1348 15.4033C15.1348 16.0937 14.5751 16.6533 13.8848 16.6533C13.1944 16.6533 12.6348 16.0937 12.6348 15.4033C12.6348 14.713 13.1944 14.1533 13.8848 14.1533C14.5751 14.1533 15.1348 14.713 15.1348 15.4033Z'
        stroke={color}
      />
      <path
        d='M11.3652 8.60059C11.3652 9.29094 10.8056 9.85059 10.1152 9.85059C9.42488 9.85059 8.86523 9.29094 8.86523 8.60059C8.86523 7.91023 9.42488 7.35059 10.1152 7.35059C10.8056 7.35059 11.3652 7.91023 11.3652 8.60059Z'
        stroke={color}
      />
      <path
        d='M9.84863 13.8877C9.84863 14.5781 9.28899 15.1377 8.59863 15.1377C7.90828 15.1377 7.34863 14.5781 7.34863 13.8877C7.34863 13.1973 7.90828 12.6377 8.59863 12.6377C9.28899 12.6377 9.84863 13.1973 9.84863 13.8877Z'
        stroke={color}
      />
      <path
        d='M1.88057 15.824L1.93306 15.5966C2.43013 17.0574 3.26126 18.3873 4.35635 19.4744L4.12889 19.4219C2.51245 19.0485 1.50766 17.4405 1.88056 15.824C1.88056 15.824 1.88057 15.824 1.88057 15.824Z'
        stroke={color}
      />
      <path
        d='M8.17854 1.88265L8.1786 1.88267L8.40588 1.93514C6.9452 2.43213 5.61533 3.2631 4.52829 4.35819L4.58075 4.13095L4.58076 4.13088C4.95368 2.5145 6.56215 1.50931 8.17854 1.88265Z'
        stroke={color}
      />
      <path
        d='M22.1189 8.18049L22.1189 8.18055L22.0664 8.40784C21.5694 6.94715 20.7384 5.61729 19.6433 4.53024L19.8706 4.5827L19.8707 4.58272C21.487 4.95563 22.4922 6.5641 22.1189 8.18049Z'
        stroke={color}
      />
      <path
        d='M15.822 22.1208L15.5947 22.0683C17.0554 21.5713 18.3854 20.7401 19.4725 19.645L19.4199 19.8725C19.0466 21.489 17.4385 22.4937 15.822 22.1208Z'
        stroke={color}
      />
    </svg>
  );
}

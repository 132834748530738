const colors = require("tailwindcss/colors")

export const defaultIconColor = colors.gray[700]

export type IconProps = {
  color?: string
  size?: number
  className?: string
}

export { default as CopyIcon } from "./Copy"
export { default as YouTubeIcon } from "./YouTubeIcon"
export { default as InfoIcon } from "./Info"
export { default as CalendarIcon } from "./Calendar"
export { default as UploadIcon } from "./Upload"
export { default as ClockIcon } from "./ClockIcon"
export { default as ExpandLessIcon } from "./ExpandLessIcon"
export { default as LensCloseIcon } from "./LensClose"
export { default as LensListIcon } from "./LensList"
export { default as SearchIcon } from "./Search"
export { default as AccountIcon } from "./Account"
export { default as CartIcon } from "./Cart"
export { default as ArrowDownIcon } from "./ArrowDown"
export { default as CircleCheckIcon } from "./CircleCheck"
export { default as CircleWarningIcon } from "./CircleWarning"
export { default as EditIcon } from "./Edit"
export { default as UserSearchIcon } from "./UserSearch"
export { default as HeartIcon } from "./HeartIcon"
export { default as LocationIcon } from "./Location"
export { default as LogoutIcon } from "./Logout"
export { default as CloseIcon } from "./Close"
export { default as RateStarIcon } from "./RateStar"
export { default as SupportIcon } from "./Support"
export { default as AddIcon } from "./Add"
export { default as SendIcon } from "./Send"
export { default as AttachmentIcon } from "./Attachment"
export { default as ViewIcon } from "./View"
export { default as TrashIcon } from "./Trash"
export { default as CheckIcon } from "./Check"
export { default as MoreVertIcon } from "./MoreVert"
export { default as Account2Icon } from "./Account2"
export { default as CallIcon } from "./Call"
export { default as PostalCode } from "./PostalCode"
export { default as LikeIcon } from "./LikeIcon"
export { default as ArrowUpIcon } from "./ArrowUp"
export { default as DiscountIcon } from "./Discount"
export { default as FilterIcon } from "./Filter"
export { default as ArrowForwardIcon } from "./ArrowForward"
export { default as ArrowBackIcon } from "./ArrowBack"
export { default as ShareIcon } from "./Share"
export { default as ChevronLeftIcon } from "./ChevronLeft"
export { default as ChevronRightIcon } from "./ChevronRight"
export { default as ArrowRightIcon } from "./ArrowRight"
export { default as CheckBoxFilled } from "./CheckBoxFilled"
export { default as CheckBoxBlanked } from "./CheckBoxBlanked"
export { default as HeartFilledIcon } from "./HeartFilled"
export { default as PlusIcon } from "./Plus"
export { default as MinusIcon } from "./Minus"
export { default as CircleBlankIcon } from "./CircleBlank"
export { default as CircleFilledIcon } from "./CircleFilled"
export { default as ExpandMoreIcon } from "./ExpandMore"
export { default as MenuIcon } from "./Menu"
export { default as DownloadIcon } from "./Download"
export { default as DescriptionIcon } from "./Description"
export { default as PlayIcon } from "./PlayIcon"
export { default as MagnifierIcon } from "./MagnifierIcon"
export { default as AparatIcon } from "./AparatIcon"
export { default as TelegramIcon } from "./TelegramIcon"
export { default as WhatsAppIcon } from "./WhatsAppIcon"
export { default as HomeIcon } from "./HomeIcon"
export { default as HomeIcon2 } from "./HomeIcon2"
export { default as PhoneIcon } from "./PhoneIcon"
export { default as PrinterIcon } from "./PrinterIcon"
export { default as EmailIcon } from "./EmailIcon"
export { default as PauseIcon } from "./PauseIcon"
export { default as CategoryIcon } from "./CategoryIcon"
export { default as Exclamation2Icon } from "./Exclamation2"
export { default as GuarrantyIcon } from "./Guarranty"

import React from "react";
import { IconProps, defaultIconColor } from ".";

export default function StarIcon({
  color = defaultIconColor,
  size = 18,
  className,
}: IconProps) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 18 18"
      fill="none"
      className={className}
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_1807_49890)">
        <path
          d="M9 12.9263L4.63425 15L5.541 10.41L2.25 7.044L6.88425 6.46575L9 2.25L11.1158 6.46575L15.75 7.044L12.459 10.41L13.3657 15L9 12.9263Z"
          fill={color}
          stroke={color}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_1807_49890">
          <rect width="18" height="18" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}

import React from "react";
import { IconProps, defaultIconColor } from ".";

export default function TelegramIcon({
  color = defaultIconColor,
  size = 18,
}: IconProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        d="M12.8292 16.3707L10.3398 18.7926C9.90824 19.2122 9.18613 19.0265 9.01133 18.4503L7.35254 12.9893"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.6137 9.16345C15.6137 9.16345 12.4926 11.9795 10.8917 13.425C10.4131 13.8565 10.4529 14.616 10.9712 14.9982L17.4545 19.7877C18.0922 20.2591 19.0036 19.9131 19.1688 19.1367L22.3525 4.13161C22.5069 3.40589 21.7944 2.79951 21.1024 3.06593L2.39878 10.2798C1.84786 10.4919 1.87438 11.2791 2.43736 11.4551L7.35226 12.9886"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

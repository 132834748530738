import React from "react"
import { IconProps, defaultIconColor } from "."

export default function LensClose({
  color = defaultIconColor,
  size = 36,
}: IconProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 36 36"
      fill={color}
    >
      <mask
        id="mask0_3564_46702"
        style={{ maskType: "alpha" }}
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="36"
        height="36"
      >
        <rect width="36" height="36" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_3564_46702)">
        <path
          d="M12.5998 24.45L17.9998 19.05L23.3998 24.45L24.4498 23.4L19.0498 18L24.4498 12.6L23.3998 11.55L17.9998 16.95L12.5998 11.55L11.5498 12.6L16.9498 18L11.5498 23.4L12.5998 24.45ZM8.43726 30C7.73726 30 7.15626 29.769 6.69426 29.307C6.23126 28.844 5.99976 28.2625 5.99976 27.5625V8.4375C5.99976 7.7375 6.23126 7.1565 6.69426 6.6945C7.15626 6.2315 7.73726 6 8.43726 6H27.5623C28.2623 6 28.8438 6.2315 29.3068 6.6945C29.7688 7.1565 29.9998 7.7375 29.9998 8.4375V27.5625C29.9998 28.2625 29.7688 28.844 29.3068 29.307C28.8438 29.769 28.2623 30 27.5623 30H8.43726Z"
          fill="#BB202E"
        />
      </g>
    </svg>
  )
}
